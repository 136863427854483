import React, { useState, useEffect, useRef } from "react";
import { loadCSV } from "../../utils/Utils";
import SearchBox from "./SearchBox";
import SongsList from "./SongsList";

const SongsTable = ({ appVersion }) => {
  const [songs, setSongs] = useState([]);
  const [filteredSongs, setFilteredSongs] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isMaximized, setIsMaximized] = useState(false);

  function usePrevious(value) {
    const ref = useRef();

    useEffect(() => {
      ref.current = value;
    }, [value]);

    return ref.current;
  }

  const songsListRef = useRef(null);
  const prevIsMaximized = usePrevious(isMaximized);

  // Load Songs
  useEffect(() => {
    loadCSV(`${appVersion}songs/song_list.csv`, (data) => {
      setSongs(data);
      setFilteredSongs(data);
      setIsLoading(false);
    });
  }, [appVersion]);

  const toggleMaximize = () => {
    setIsMaximized(!isMaximized);
  };

  useEffect(() => {
    if (prevIsMaximized && !isMaximized && songsListRef.current) {
      songsListRef.current.scrollIntoView({ behavior: "instant" });
    }
  }, [prevIsMaximized, isMaximized]);

  return (
    <div
      ref={songsListRef}
      className={`songs-table-container ${isMaximized ? "maximized" : ""}`}
    >
      <SearchBox
        setFilteredSongs={setFilteredSongs}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        songs={songs}
        isMaximized={isMaximized}
        onMaximize={toggleMaximize}
      />
      <SongsList filteredSongs={filteredSongs} isLoading={isLoading} />
    </div>
  );
};

export default SongsTable;
